import React from "react";
import styled, { ThemeProvider } from "styled-components";
import Contact from "../components/contact/Contact";
import Footer from "../components/Footer";
import HeroGeneral from "../components/heroGeneral";
import Menu from "../components/Menu";
import MetaComponent from "../components/MetaComponent";
import { lightTheme } from "../components/theme";
import { graphql } from "gatsby";

const Wrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;
  background: ${(props) => props.theme.brandGrey}; ;
`;

const ContactPage = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="Contact" />
      <Wrapper>
        <Menu />
        <HeroGeneral title="Contact" />
        <Contact />
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
