import React, { useState } from "react";
import * as styles from "./contact.module.css";

const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [added, setAdded] = useState(false);

  const addContact = async () => {
    try {
      const response = await fetch("https://dev.api.keyfi.com/users/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });
      if (response.status !== 404) {
        setAdded(true);
      }
    } catch (error) {
      setAdded(false);
    }
  };

  return (
    <div className={styles.contactWrapper}>
      <div className={styles.contactForm}>
        <div className={styles.inputFields}>
          <div>
            <h4>Name</h4>
            <input
              id="name"
              placeholder="Your name"
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            ></input>
          </div>
          <div>
            <h4>Email</h4>
            <input
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              id="email"
              type="email"
              placeholder="Your e-mail address"
            ></input>
          </div>
        </div>
        <h4>Message</h4>
        <textarea
          onChange={(e) => setForm({ ...form, message: e.target.value })}
          placeholder="Your message"
          rows="4"
        ></textarea>
        <button onClick={addContact}>Submit</button>
        {added && (
          <div className={styles.successMessage}>
            We have received your message. We will contact you as soon as
            possible.
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
